// index.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import ScrollToTop from './ScrollToTop';
import About from './pages/about';
import Bonding from './pages/bonding';
import SBDC from './pages/sbdc';
import Sipps from './pages/sipps';
import WT from './pages/wt';
import FOTG from './pages/fotg';
import Home from './pages/home';
import CommerDash from './pages/commerdash';
import PasswordComponent from './PasswordComponent';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Index = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordSubmit = (enteredPassword) => {

    const correctPassword = '5555';

    if (enteredPassword === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <React.StrictMode>
      <BrowserRouter>
        {isAuthenticated ? (
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="bonding" element={<Bonding />} />
                <Route path="sbdc" element={<SBDC />} />
                <Route path="sipps" element={<Sipps />} />
                <Route path="WT%20System" element={<WT />} />
                <Route path="fotg" element={<FOTG />} />
                <Route path="commerdash" element={<CommerDash />} />
              </Route>
            </Routes>
          </ScrollToTop>
        ) : (
          <PasswordComponent onPasswordSubmit={handlePasswordSubmit} />
        )}
      </BrowserRouter>
    </React.StrictMode>
  );
};

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
