// PasswordComponent.js
import React, { useState } from 'react';

const PasswordComponent = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(password);
  };

  return (
    <div className="password-container">
      <h1>Hello There!</h1>
      <br></br>
      <div>I'm Amber, welcome to my site.</div>
      <div>
        For access, please use the password provided or
        reach me @ <a href="https://www.linkedin.com/in/amberschen/">LinkedIn</a>
      </div>
      <form onSubmit={handleSubmit}>
        <label>Password:&nbsp;</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />&nbsp;
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PasswordComponent;
